import { Button } from "../blocks/Button";
import { ReactComponent as Logo } from "../assets/fruno-logo.svg";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="kapcsolat" className="text-left">
      <div className="info-bar flex lg:py-10 flex-col lg:flex-row ">
        <div className="contact-cta w-full flex flex-col lg:items-start items-center lg:border-none border-b border-fruno-secondary py-[60px] lg:py-0 gap-3 lg:px-[60px] px-3 ">
          <h3 className="font-serif text-lg lg:text-xl">
            Vedd fel velünk a kapcsolatot!
          </h3>
          {/* TODO add correct email address */}
          <Button
            underline={true}
            text="Kapcsolatfelvétel"
            link="mailto:info@fruno.hu"
            aria-label="Kapcsolatfelvétel emailen keresztül"
          ></Button>
        </div>
        <div className="w-full flex justify-between flex-col lg:flex-row items-center lg:items-start lg:pl-0 lg:pr-[60px] px-3 text-sm md:text-base">
          <div className="contact-details flex flex-col mt-5 lg:mt-0 mb-3 lg:mb-0">
            <a href="mailto:info@fruno.hu block">info@fruno.hu</a>
            <a href="tel:+3672200201 block">+36 72 200 201</a>
          </div>
          <div className="socials flex gap-3">
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61558942481956"
              rel="noreferrer"
            >
              <img
                src="/facebook-icon.svg"
                alt="Fruno a facebookon"
                width="32"
                height="32"
                loading="lazy"
              />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/fruno_fruitwines?igsh=MTdmdWN5dmoweWMwNQ%3D%3D"
              rel="noreferrer"
            >
              <img
                src="/instagram-icon.svg"
                alt="Fruno a facebookon"
                width="32"
                height="32"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="px-3 lg:px-[60px] pt-6 lg:pt-[60px] gap-6 lg:gap-[60px] flex flex-col pb-5 lg:pb-6 text-sm md:text-base">
        <div className="logo-container ">
          <Logo fill="#C26D71" className="w-full" />
        </div>
        <div className="flex gap-3 justify-center text-sm">
          <p className="lg:flex-1 copyright-year lg:w-full ">©{currentYear}</p>
          <p className="icon w-7 hidden lg:block">
            <img
              src="/moment-icon.svg"
              alt="Fruno pillanatok ikon"
              width="28"
              height="28"
              loading="lazy"
            />
          </p>
          <p className="lg:flex-1 copyright-text lg:w-full  lg:text-right">
            MINDEN JOG FENNTARTVA
          </p>
        </div>
      </div>
    </footer>
  );
};
