// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { SwiperNavButtons } from "../blocks/SwiperNavButtons";

export const WineSlider = () => {
  /* TODO: Add correct texts for "tags" */
  /* TODO: Add correct images for the bottles" */
  const sliderData = [
    {
      img: "/bottle-strawberry.png",
      textSVG: "/eper.svg",
      fruit: "eper",
      description:
        "Fedezd fel kacér önmagadat a Fruno Eper nyárias kortyaiban.",
      color: "#E69A9B",
      tags: ["Fehér húsokhoz", "Tésztaételekhez", "Tejes desszertekhez"],
    },
    {
      img: "/bottle-currant.png",
      textSVG: "/ribizli.svg",
      fruit: "ribizli",
      description:
        "Csempéssz édes titokzatosságot történeteidbe a Fruno Ribizlivel.",
      color: "#5D8097",
      tags: ["Vöröshúsokhoz", "Vadételekhez", "Gyümölcstálakhoz"],
    },
    {
      img: "/bottle-cherry.png",
      textSVG: "/meggy.svg",
      fruit: "meggy",
      description:
        "Állj ki merészen a Fruno Meggy markánsan ízletes, gazdag atmoszférájában.",
      color: "#983A60",
      tags: ["Vegán ételekhez", "Paprikás ételekhez", "Mákos süteményekhez"],
    },
    {
      img: "/bottle-apple.png",
      textSVG: "/bodza-alma.svg",
      fruit: "bodza & alma",
      description:
        "Ízleld meg üde énedet a Fruno Bodza & Alma bájosan zamatos világában.",
      color: "#97A041",
      tags: ["Halételekhez", "Érett sajtokhoz", "Ázsiai ételekhez"],
    },
  ];

  return (
    <section
      id="gyumolcsborok"
      className="wine-slider min-h-screen border-b border-fruno-secondary flex flex-col justify-center overflow-hidden"
    >
      <div className="swiper-container mb-6 lg:mb-4 ">
        <Swiper
          className="!overflow-visible my-12 lg:my-14 !pt-[60px] !lg:pt-[100px]  "
          modules={[Parallax, Pagination, Navigation]}
          spaceBetween={0}
          loop={true}
          slidesPerView={2}
          centeredSlides={true}
          pagination={true}
          speed={1000}
          watchSlidesProgress={true}
          parallax={true}
          breakpoints={{
            1024: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
          }}
          onRealIndexChange={(swiper) => {}}
          onProgress={(swiper) => {
            for (let i = 0; i < swiper.slides.length; i++) {
              const slideNode = swiper.slides[i];
              const slideProgress = slideNode.progress;
              const absProgress =
                Math.round(Math.abs(slideProgress) * 100) / 100;
              let scale = 1 - absProgress * 0.1;

              slideNode.style.transform = `scale(${scale})`;
              slideNode.querySelector(".glow").style.opacity =
                0.5 - absProgress;

              slideNode.querySelector(".background-text").style.opacity =
                0.75 - absProgress;

              slideNode.querySelector(
                ".glow"
              ).style.transform = `translate(-50%, calc(-50% - 60px)) scale(${scale})`;
            }
          }}
          onSetTransition={(swiper, duration) => {
            for (let i = 0; i < swiper.slides.length; i += 1) {
              const slideEl = swiper.slides[i];
              slideEl.style.transitionDuration = `${duration}ms`;
            }
          }}
        >
          {/* TODO: add responsive image styles */}
          {sliderData.map((wineType, index) => (
            <SwiperSlide key={index} className="my-12 lg:my-14 bg-opacity-50">
              {({ isActive, isPrev, isNext }) => (
                <div className="relative">
                  <div className="">
                    <div
                      style={{
                        background: `radial-gradient(50% 50% at 50% 50%, ${wineType.color} 0%, rgba(249, 202, 189, 0.00) 100%)`,
                      }}
                      className={`glow transition-all ease-in-out duration-500 absolute rounded-full w-[100vw] h-[100vw] md:w-[75vw] md:h-[75vw] lg:w-[60vw] lg:h-[60vw] left-1/2 top-1/2 -z-10 ${
                        isActive ? "delay-700" : ""
                      }`}
                    ></div>
                    <img
                      className="background-text transition-all duration-500 ease-in-out pointer-events-none absolute inset-0 w-full lg:w-[85%] -z-10 top-1/2 left-1/2 -translate-x-1/2 translate-y-[calc(-50%-60px)]"
                      src={wineType.textSVG}
                      alt={wineType.fruit}
                      width="930"
                      height="402"
                      loading="lazy"
                    />
                  </div>
                  <div className="z-20">
                    <img
                      src={wineType.img}
                      alt={`${wineType.fruit} ízű üveges Fruno`}
                      className="mx-auto w-auto h-72 lg:h-[500px] mb-4 lg:mb-5"
                      width="443"
                      height="1620"
                      loading="lazy"
                    />

                    <div className="text-container max-w-[512px] mx-auto">
                      <h3
                        className={`label !text-base lg:!text-md mb-4 lg:mb-2 transition-all ease-in-out duration-500 ${
                          isActive
                            ? "delay-700"
                            : isPrev
                            ? "opacity-0 -translate-x-1 lg:-translate-x-4"
                            : "opacity-0 translate-x-1 lg:translate-x-4"
                        }`}
                      >
                        {wineType.fruit}
                      </h3>
                      <p
                        className={`description text-sm lg:text-base mb-6 max-w max-w-96 mx-auto transition-all ease-in-out duration-700 ${
                          isActive
                            ? "delay-700"
                            : isPrev
                            ? "opacity-0 -translate-x-2 lg:-translate-x-6"
                            : "opacity-0 translate-x-2 lg:translate-x-6"
                        }`}
                      >
                        {wineType.description}
                      </p>

                      <div
                        className={`tags flex flex-wrap justify-center gap-2 transition-all ease-in-out duration-1000 ${
                          isActive
                            ? "delay-700"
                            : isPrev
                            ? "opacity-0 -translate-x-3 lg:-translate-x-8"
                            : "opacity-0 translate-x-3 lg:translate-x-8"
                        }`}
                      >
                        {wineType.tags.map((tag, index) => (
                          <span
                            key={index}
                            className="text-xs uppercase lg:text-sm font-medium lg:font-normal rounded-full border border-fruno-secondary px-4 py-1"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </SwiperSlide>
          ))}
          <SwiperNavButtons
            hideOnMobile={true}
            padding={"large"}
          ></SwiperNavButtons>
        </Swiper>
      </div>
    </section>
  );
};
