import { useSwiper } from "swiper/react";

export const SwiperNavButtons = (padding = "small", hideOnMobile = false) => {
  const swiper = useSwiper();

  let paddingLeft = null;
  let paddingRight = null;

  switch (padding) {
    case "large":
      paddingLeft = "xl:left-56";
      paddingRight = "xl:right-56";
      break;

    default:
      paddingLeft = "left-3 xl:left-14";
      paddingRight = "right-3 xl:right-14";
      break;
  }

  return (
    <div
      className={`swiper-navigation ${hideOnMobile ? "" : "hidden lg:block"}`}
    >
      <button
        className={`prev-button absolute ${paddingLeft} top-1/2 -translate-y-1/2 z-20 swiper-button`}
        onClick={() => swiper.slidePrev()}
        aria-label="Előző slide"
      >
        <img
          src="/arrow-left.svg"
          alt="Előző slide"
          className="w-full lg:w-12 relative"
          width="48"
          height="22"
          loading="lazy"
        />
      </button>
      <button
        className={`next-button absolute ${paddingRight} top-1/2 -translate-y-1/2 z-20 swiper-button`}
        onClick={() => swiper.slideNext()}
        aria-label="Következő slide"
      >
        <img
          src="/arrow-right.svg"
          alt="Következő slide"
          className="w-full lg:w-12 relative"
          width="48"
          height="22"
          loading="lazy"
        />
      </button>
    </div>
  );
};
