import { useState } from "react";
import { ValueItem } from "../blocks/ValueItem";

export const Values = () => {
  const valuesData = [
    {
      iconURL: "value-icon.svg",
      title: "Valódi értékek",
      description:
        "Hisszük, hogy a legértékesebb pillanatok azok, amikben gondtalanul adhatod a legvalódibb önmagadat. Töltsd meg napjaidat olyan gazdag és természetes pillanatokkal, mint amilyen a Fruno is!",
    },
    {
      iconURL: "moment-icon.svg",
      title: "Közös pillanatok",
      description:
        "Mutasd meg másoknak is, milyen vagy igazán! A tartalmasan töltött közös idő, mély beszélgetések és az önfeledt kapcsolódás a legédesebb korty, amit megízlelhetsz.",
    },
    {
      iconURL: "inspiration-icon.svg",
      title: "Legyél te az ihlet!",
      description:
        "A ragyogó önazonosság távolról is tisztán látható. Gondolj bele, hány embert inspirálhatsz a valódi pillanatokra csupán azzal, hogy önmagadként vagy jelen!",
    },
  ];

  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);

  return (
    <section id="ertekeink" className="values-container">
      <h2 className="header text lg:mx-auto text-lg lg:text-2xl font-serif py-6 lg:py-10 xl:py-[60px] px-3 lg:px-[60px] mx-auto text-center max-w-80 lg:max-w-[720px] xl:max-w-none">
        Az igazán fontos dolgok a palackon kívül vannak
      </h2>

      <div className="accordion-container bg-fruno-secondary flex-col lg:flex-row flex gap-[1px] border-t border-b border-fruno-secondary">
        {valuesData.map((value, index) => (
          <ValueItem
            key={index}
            index={index}
            value={value}
            activeAccordionIndex={activeAccordionIndex}
            setActiveAccordionIndex={setActiveAccordionIndex}
          ></ValueItem>
        ))}
      </div>
    </section>
  );
};
