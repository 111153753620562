/* import { useState } from "react"; */
import "./App.css";
import {
  Header,
  Hero,
  TextBanner,
  WineSlider,
  TripleBanner,
  Values,
  Testimonials,
  ImageBanner,
  LargeImage,
  Footer,
} from "./components";

function App() {
  return (
    <div className="App bg-fruno-sand">
      <Header></Header>
      <Hero></Hero>
      <TextBanner></TextBanner>
      <WineSlider></WineSlider>
      <Values></Values>
      <ImageBanner></ImageBanner>
      <TripleBanner></TripleBanner>
      <Testimonials></Testimonials>
      <LargeImage></LargeImage>
      <Footer></Footer>
    </div>
  );
}

export default App;
