import React from "react";

export const LargeImage = () => {
  return (
    <section className="image-banner h-[90vh] min-h-[600px] lg:min-h-[900px]  bg-slate-400 flex flex-col justify-center items-center relative">
      <img
        src="/borok.jpg"
        alt="Fruno borok"
        className="absolute inset-0 w-full h-full object-cover z-0"
        width="2880"
        height="1700"
        loading="lazy"
      />
    </section>
  );
};
