import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/fruno-logo.svg";
import { ReactComponent as MenuIcon } from "../assets/menu-icon.svg";
import { ReactComponent as CloseIcon } from "../assets/close-icon.svg";

import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(
        window.scrollY >
          document?.querySelector(".hero-section").clientHeight - 120
      );
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  const [isOpen, setIsOpen] = useState(false);

  const mobileNavRef = useRef();
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useGSAP(() => {
    gsap.to(mobileNavRef.current, {
      height: isOpen ? "auto" : 0,
      ease: "power2.out",
      duration: 0.3,
    });
  }, [isOpen]);

  return (
    <header
      style={{
        background: `${
          isScrolled
            ? "#FFF8F7"
            : isOpen
            ? "#FFF8F7"
            : "linear-gradient(180deg, rgba(194, 109, 113, 0.4) 0, rgba(194, 109, 113, 0.24) 19.01%, rgba(194, 109, 113, 0) 68%)"
        }`,
      }}
      className={` fixed lg:flex grid grid-cols-2 items-center justify-between w-full px-3 md:px-8  lg:px-[60px] z-30 py-5 text-sm uppercase border-fruno-secondary ${
        isScrolled
          ? "text-fruno-main border-b"
          : isOpen
          ? "text-fruno-main border-b"
          : "text-white transition-all"
      }`}
    >
      <nav className="left hidden lg:flex flex-1 justify-between items-center">
        <a className="transition-colors" href="#gyumolcsborok">
          Gyümölcsborok
        </a>
        <a className="transition-colors" href="#ertekeink">
          Értékeink
        </a>
      </nav>
      <div className="flex-1 ">
        <Logo
          onClick={() => {
            window.scrollTo({ top: 0 });
            setIsOpen(false);
          }}
          fill={isScrolled ? "#C26D71" : isOpen ? "#C26D71" : "white"}
          className="w-32 lg:w-44 lg:mx-auto  cursor-pointer"
        />
      </div>
      <button
        onClick={() => {
          handleClick();
        }}
        className="ml-auto icon-container w-7 h-7 lg:hidden relative"
        aria-label={`Menü ${isOpen ? "bezárása" : "kinyitása"}`}
      >
        <MenuIcon
          fill={isScrolled ? "#C26D71" : isOpen ? "#C26D71" : "white"}
          className={`${isOpen ? "opacity-0" : ""} transition-opacity`}
        ></MenuIcon>
        <CloseIcon
          className={`${
            isOpen ? "" : "opacity-0"
          } absolute w-7 h-7 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transition-opacity`}
          fill={isScrolled ? "#C26D71" : isOpen ? "#C26D71" : "white"}
        ></CloseIcon>
      </button>

      <nav className="right hidden left lg:flex flex-1 justify-between items-center">
        <a className="transition-colors" href="#fruno-tippek">
          Fruno tippek
        </a>
        <a className="transition-colors" href="#kapcsolat">
          Kapcsolat
        </a>
      </nav>

      <nav ref={mobileNavRef} className="mobile h-0 overflow-hidden lg:hidden">
        <div className="pt-5 flex flex-col justify-start text-left">
          <a
            onClick={() => {
              setIsOpen(false);
            }}
            className="py-2"
            href="#gyumolcsborok"
          >
            Gyümölcsborok
          </a>
          <a
            onClick={() => {
              setIsOpen(false);
            }}
            className="py-2"
            href="#ertekeink"
          >
            Értékeink
          </a>
          <a
            onClick={() => {
              setIsOpen(false);
            }}
            className="py-2"
            href="#fruno-tippek"
          >
            Fruno tippek
          </a>
          <a
            onClick={() => {
              setIsOpen(false);
            }}
            className="py-2"
            href="#kapcsolat"
          >
            Kapcsolat
          </a>
        </div>
      </nav>
    </header>
  );
};

/*  */
