import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export const ValueItem = ({
  value,
  index,
  activeAccordionIndex,
  setActiveAccordionIndex,
}) => {
  const isOpen = index === activeAccordionIndex;
  const accordionItem = useRef();

  const changeActiveIndex = (index) => {
    index === activeAccordionIndex
      ? setActiveAccordionIndex(-1)
      : setActiveAccordionIndex(index);

    console.log(activeAccordionIndex);
  };

  useGSAP(() => {
    gsap.to(accordionItem.current.querySelector(".accordion-content-mobile"), {
      height: isOpen ? "auto" : 0,
      ease: "power2.out",
      duration: 0.3,
    });

    gsap.to(accordionItem.current.querySelector(".plus-icon"), {
      rotate: isOpen ? -45 : 0,
    });
  }, [isOpen]);

  return (
    <div
      ref={accordionItem}
      className="bg-fruno-sand lg:px-8 lg:py-10 xl:px-[60px] xl:py-20 flex-1"
      key={index}
    >
      <button
        onClick={() => changeActiveIndex(index)}
        className="accordion-toggle flex items-center justify-between w-full relative lg:cursor-default"
        aria-label={`Elem ${isOpen ? "bezárása" : "kinyitása"}`}
      >
        <div className="label !text-base lg:!text-md text-left lg:text-center flex items-center lg:flex-col lg:items-center lg:justify-center w-full px-3 py-5 gap-4 lg:py-0 lg:pb-6">
          <img
            className="w-10 h-10 lg:w-28 lg:h-28 xl:w-32 xl:h-32 lg:mb-10"
            src={value.iconURL}
            alt={`${value.title} ikon`}
            width="128"
            height="128"
            loading="lazy"
          />
          <h3>{value.title}</h3>
        </div>
        <img
          src="/plus-icon.svg"
          alt="plus-icon"
          className="plus-icon mr-3 lg:hidden"
          width="18"
          height="18"
          loading="lazy"
        />
      </button>
      <div
        className={`accordion-content-mobile pl-[68px] pr-4 text-left  lg:h-auto overflow-hidden text-base lg:text-center xl:max-w-96 xl:mx-auto lg:hidden`}
      >
        <p className="mb-6">{value.description}</p>
      </div>
      <div
        className={`accordion-content hidden lg:block text-center  lg:h-auto overflow-hidden text-base lg:text-center xl:max-w-96 xl:mx-auto`}
      >
        <p className="mb-6">{value.description}</p>
      </div>
    </div>
  );
};
