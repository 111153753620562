import React from "react";

export const ImageBanner = () => {
  return (
    <section className="image-banner h-[90vh] min-h-[600px] lg:min-h-[900px]  bg-slate-400 flex flex-col justify-center items-center relative">
      <img
        src="/why-fruno@2x.jpg"
        alt="A Fruno egy meghívás valódi élményekre, ahol magabiztosan lehetsz
        önmagad"
        className="absolute inset-0 w-full h-full object-cover z-0"
        width="2880"
        height="1700"
        loading="lazy"
      />
      <div className="max-w-96 md:max-w-[512px] lg:max-w-[1024px] z-20">
        <h3 className="label text-white mb-2 lg:mb-4">Miért fruno?</h3>
        <p className="text-lg lg:text-2xl font-serif text-white px-3 mb-4 lg:mb-8 text-center">
          A Fruno egy meghívás valódi élményekre, ahol magabiztosan lehetsz
          önmagad
        </p>
      </div>
    </section>
  );
};
