export const TextBanner = () => {
  return (
    <section className="py-[60px] lg:py-[100px] px-5 lg:px-[140px] border-b border-fruno-secondary flex flex-col justify-center items-center">
      <div className="lg:max-w-[1160px] max-w-96 md:max-w- flex flex-col gap-2 lg:gap-5">
        <h3 className="label">Mi a fruno?</h3>
        <p className="text-lg lg:text-2xl font-serif">
          Kedvenc gyümölcseid palackba zárva. Ismerd meg a borok világának új
          dimenzióját, ahol nem a szőlő uralkodik!
        </p>
      </div>
    </section>
  );
};
