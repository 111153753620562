export const Button = ({
  link = "#",
  text = "button text is missing",
  color = "fruno-main",
  underline = false,
  glow = false,
}) => {
  let bgClass = null;

  switch (color) {
    case "white":
      bgClass = "after:bg-white";
      break;
    default:
      bgClass = "after:bg-fruno-main";
      break;
  }

  return (
    <a
      href={link}
      className={`${
        glow ? "link-glow" : ""
      } main-button text-sm uppercase text-${color} ${bgClass} relative ${
        underline ? "link-underline" : ""
      }`}
    >
      {text}
    </a>
  );
};
