// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export const TripleBanner = () => {
  const bannerData = [
    {
      imgURL: "/spritz.png",
      imgAlt: "Fruno Spritz kép",
      header: "Fruno Spritz",
      description:
        "A legfrissítőbb élményekért tölts 1 dl szódát vagy tonikot 2 dl Fruno-hoz! Tálald jéggel és ízlés szerint bolondítsd meg friss bazsalikommal vagy mentával!",
    },
    {
      imgURL: "/fuszeres.png",
      imgAlt: "Fűszeres ételekkel kép",
      header: "Fűszeres ételekkel",
      description:
        "A Fruno teljes kínálata édes, így kiváló társként szolgál fűszeres ételekhez. Párosítsd indiai, kelet-ázsiai vagy éppen barbeque vacsorádhoz!",
    },
    {
      imgURL: "/desszert.png",
      imgAlt: "Édes pillanatokhoz kép",
      header: "Édes pillanatokhoz",
      description:
        "Gyümölcsboraink mind édesek, így tökéletes kiegészítők minden desszerthez. Koronázd meg az étkezésed a Fruno bűbájos ízeivel!",
    },
  ];

  return (
    <section
      id="fruno-tippek"
      className="triple-banner py-[60px] lg:py-[100px] border-b border-fruno-secondary"
    >
      <div className="header-container px-3 mb-6 lg:mb-16">
        <h3 className="label mb-2 lg:mb-5 mx-auto">Fruno Tippek</h3>
        <h2 className="text mx-auto text-lg lg:text-2xl font-serif max-w-96 md:max-w-[512px] lg:max-w-[900px]">
          Próbáld ki úgy is, ahogy mi szeretjük!
        </h2>
      </div>

      <div className="swiper-container px-3 lg:px-[60px]">
        <Swiper
          modules={[Pagination]}
          pagination={true}
          spaceBetween={12}
          loop={true}
          slidesPerView={1}
          breakpoints={{
            1024: {
              slidesPerView: 3,
              spaceBetween: 60,
              loop: false,
            },
          }}
        >
          {/* TODO: add responsive image styles */}
          {bannerData.map((banner, index) => (
            <SwiperSlide key={index} className="pb-10">
              <div className="md:max-w-[512px] mx-auto">
                <img
                  src={banner.imgURL}
                  alt={banner.imgAlt}
                  className="w-full mb-6"
                  width="780"
                  height="910"
                  loading="lazy"
                />
                <h3 className="label !text-base lg:!text-md mb-4">
                  {banner.header}
                </h3>
                <p>{banner.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};
