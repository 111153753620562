import { Button } from "../blocks/Button";

export const Hero = () => {
  return (
    <section className="h-screen bg-slate-400 flex flex-col justify-center items-center relative hero-section">
      {/* TODO: add responsive image styles */}
      <img
        src="/hero.jpg"
        alt="Gyümölcsbor valódi gyümölcsből valódi pillanatokhoz"
        className="absolute inset-0 w-full h-full object-cover"
        width="2640"
        height="1650"
        loading="lazy"
      />
      <div className="max-w-96 lg:max-w-[900px] z-20">
        <h1 className="text-xl lg:text-3xl font-serif text-white px-3 mb-4 lg:mb-8 text-center">
          Gyümölcsbor valódi gyümölcsből valódi pillanatokhoz
        </h1>
        <Button
          text="ismerd meg borainkat"
          link="#gyumolcsborok"
          color="white"
          glow={true}
          aria-label="Ismerd meg borainkat"
        ></Button>
      </div>
    </section>
  );
};
