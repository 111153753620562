// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { SwiperNavButtons } from "../blocks/SwiperNavButtons";

export const Testimonials = () => {
  const testimonialData = [
    {
      stars: 5,
      quote:
        "Végre egy finom alternatíva a barátokkal való sörözésre! Már kétszer vettem, egy epreset és egy ribizliset, de mindenképp megkóstolom a többit is. Tuti, hogy mostantól ez lesz a go-to italom.",
      name: "K. Lilla",
      location: "Budapest",
    },
    {
      stars: 5,
      quote:
        "Eddig az eperbort kóstoltam, csajos estékhez ideális. Meg is lepődtem, mert nem csak édes, de olyan, mintha maga a friss eper lenne a pohárban.",
      name: "G. Sára",
      location: "Szeged",
    },
    {
      stars: 5,
      quote:
        "A meggybor a téli esték megmentője! Kinyitod a palackot, beszippantod az illatát, és máris úgy érzed, mintha nyár lenne. Kell ennél több egy hosszú nap után?",
      name: "F. Kata",
      location: "Sopron",
    },
  ];

  return (
    <section className="testimonials py-[60px] lg:py-[100px] border-b border-fruno-secondary">
      <div className="swiper-container relative">
        <Swiper
          modules={[Navigation]}
          spaceBetween={12}
          loop={true}
          slidesPerView={1}
          speed={1000}
        >
          {/* TODO: add responsive image styles */}
          {testimonialData.map((testimonial, index) => (
            <SwiperSlide key={index} className="px-12">
              <div className="md:max-w-[512px] lg:max-w-[712px] xl:max-w-[900px] mx-auto">
                <div className="stars-container mb-6 lg:mb-10 flex justify-center gap-2 lg:gap-4">
                  {Array.from(Array(testimonial.stars), (e, i) => {
                    return (
                      <span key={i}>
                        <img
                          src="/star-icon.svg"
                          alt="star"
                          className="lg:w-6"
                          width="14"
                          height="12"
                          loading="lazy"
                        />
                      </span>
                    );
                  })}
                </div>
                <h5 className="mb-6 lg:mb-10 text-md lg:text-xl font-serif">
                  {testimonial.quote}
                </h5>
                <div className="details-container text-center">
                  <p className="label mb-1 lg:mb-2">{testimonial.name}</p>
                  <p className="uppercase text-sm lg:text-base">
                    {testimonial.location}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <SwiperNavButtons></SwiperNavButtons>
        </Swiper>
      </div>
    </section>
  );
};
